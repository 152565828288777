<!--
 * @Date: 2024-08-26 16:31:26
 * @FilePath: \zyt-mobile-frontend\src\views\ruralFilm\pages\filmAroundDetail\index.vue
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-11-16 22:19:23
  * @Description: 周边放映-电影详情
-->
<template>
  <div>
    <!-- 添加跳转到首页的按钮 -->
    <div class="header">
<!--      <van-button type="primary" @click="goPage('/home')">返回首页</van-button>-->
      <van-nav-bar
        left-text="返回首页"
        left-arrow
        @click-left="goPage('/home')"
      ></van-nav-bar>
    </div>
    <!-- Movie Information -->
    <div class="movie-info">
      <film-info
        :movieId="movie.id"
        :movieYear="movie.year"
        :movieImg="movie.filmPicture"
        :movieTitle="movie.name"
        :movieScore="movie.score"
        :moviePlayNum="movie.clickCount"
        :movieType="movie.type"
        :movieLength="movie.filmLength"
        :movieActor="movie.actor"
        :movieLanguage="movie.language"
        :movieReleaseDate="movie.releaseDate"
        :movieReleaseArea="movie.releaseArea"
        :movieDescription="movie.synopsis"
      ></film-info>
    </div>
    <div class="film-demand-filter">
      <!-- 选择拼团类型 -->
      <select v-model="playType" class="dropdown-menu">
        <option value="">所有放映计划</option>
        <option value="click">正在拼团中</option>
        <option value="task">放映计划</option>
      </select>
    </div>
    <!-- 周边放映-个人点播 -->
    <div
      class="screening-schedule"
      v-for="schedule in movie.personalTasks"
      :key="schedule.id"
    >
      <div>
        <div class="screening-schedule-address-title">
          <span>放映地址：</span>
          <span
            class="screening-schedule-status screening-schedule-status-success"
            v-if="schedule.groupBooking === 1"
          >
            <van-icon name="check" />
            拼团成功
          </span>
          <span
            class="screening-schedule-status screening-schedule-status-warning"
            v-else-if="schedule.groupBooking === 0"
          >
            <van-icon name="clock" />
            正在拼团中
          </span>
        </div>
        <div class="screening-schedule-point">
          {{ schedule.point }}
        </div>
        <div class="screening-schedule-name">
          {{ schedule.showPointName }}
        </div>
        <div class="screening-schedule-address">
          {{ schedule.showPointAddress }}
        </div>
        <div class="screening-schedule-distance">
          距您{{ schedule.meter + 'm' }}
        </div>
        <div class="screening-schedule-time-title">放映时间：</div>
        <div class="screening-schedule-time">
          {{ schedule.expectPlayStartTime }} - {{ schedule.expectPlayEndTime }}
        </div>
        <div class="screening-schedule-bottom">
          <div class="left">
            <div>
              已拼
              <span
                :style="{
                  color: schedule.groupBooking === 1 ? '#02b47a' : '#FF6A2A',
                }"
              >{{ schedule.spellGroup }}</span>
              人
            </div>
            <div style="padding: 0.1rem 0">
              <van-progress
                :percentage="((schedule.spellGroup / schedule.groupNumber) * 100).toFixed(0) > 100 ? 100 : ((schedule.spellGroup / schedule.groupNumber) * 100).toFixed(0)"
                :status="schedule.groupBooking === 1 ? 'success' : 'exception'"
                stroke-width="8"
              />
            </div>
            <div>
              <van-count-down
                :time="getEndTimeDiff(schedule.expectPlayEndTime)"
                format="DD天HH时mm分ss秒"
              />
            </div>
          </div>
          <div class="right">
            <van-button
              :disabled="schedule.joined"
              type="warning"
              @click="joinGroup(schedule)"
              color="#FF6A2A"
              round
            >
              {{ schedule.joined ? '已拼团' : '立即拼团' }}
            </van-button>
          </div>
        </div>
      </div>
    </div>
    <van-empty
      description="暂无放映计划或点播记录"
      v-if="!movie.personalTasks.length"
    />
  </div>
</template>

<script>
import filmInfo from './modules/filmInfo'
import { api } from '@/api'
import { Dialog, Toast,Progress } from 'vant'
import common from '@/utils/common'

export default {
  components: {
    filmInfo,
    vanProgress:Progress,
  },
  mounted() {
    const { filmId, showPointId } = this.$route.query
    if (filmId) {
      this.filmId = filmId
      const { userPoint } = this.$store.state
      if (userPoint) {
        this.getMovieInfo(filmId, userPoint.id)
      } else if (showPointId) {
        this.getMovieInfo(filmId, showPointId)
      } else {
        Dialog.alert({
          title: '提示',
          message: '参数错误',
        })
      }
    } else {
      Dialog.alert({
        title: '提示',
        message: '未知影片',
      })
    }
  },
  data() {
    return {
      playType: '',
      filmId: '',
      movie: {
        personalTasks: [],
        // 其他电影信息...
      },
    }
  },
  methods: {
    getMovieInfo(filmId, showPointId) {
      api.getFilmAroundDetail({ id: filmId, showPointId }).then((res) => {
        const { success, result } = res
        if (success) {
          this.movie = result
        } else {
          common.DialogStr(res.message)
        }
      })
    },
    getEndTimeDiff(endTime) {
      const now = new Date()
      const end = new Date(endTime)
      return end.getTime() - now.getTime()
    },
    joinGroup(schedule) {
      if (this.checkLogin()) {
        Dialog.confirm({
          title: '确定要加入该拼团吗？',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          api.joinGroup({ id: schedule.taskId }).then((res) => {
            const { success, message } = res
            if (success) {
              Toast('加入成功')
              this.getMovieInfo(this.filmId, this.userPoint.id)
            } else {
              Toast(message)
            }
          })
        })
      }
    },
    checkLogin() {
      const token = this.$store.state.userInfo.token
      if (!token) {
        this.$router.replace({ path: '/', query: { redirect: this.$route.fullPath } })
        return false
      }
      return true
    },
    goPage(path) {
      this.$router.push(path)
      }
  },
  watch: {
    playType(val) {
      this.getMovieInfo(this.filmId, this.userPoint.id)
    },
  },
}
</script>

<style scoped lang="less">
.film-around-detail {
  /* Add your styles here */
}

.movie-info {
  /* Add your styles here */
  padding: 12px;
}

.screening-schedule {
  /* Add your styles here */
  margin: 12px;
  background: #fff;
  border-radius: 4px;
  padding: 12px;
  color: @color-gray;
  font-size: @font-size-base;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);

  &-address {
    &-title {
      display: flex;
      justify-content: space-between;
      font-size: @font-size-base;
      margin: 4px 0;
    }
  }

  &-name {
    font-size: 18px;
    font-weight: bold;
    font-family: @font-family-base;
    color: #363a44;
    margin: 4px 0;
  }

  &-address {
    font-size: @font-size-base;
    margin: 4px 0;
  }

  &-status {
    color: #ff6a2a;

    &-success {
      color: #57c54a;
    }
  }

  &-time {
    margin: 4px 0;
    font-size: 18px;
    font-weight: bold;
    font-family: @font-family-base;
    color: #363a44;

    &-title {
      margin-top: 24px;
    }
  }

  &-bottom {
    margin: 8px 0 4px 0;
    height: 48px;
    display: flex;
    justify-content: space-between;
  }

  &-distance {
    color: @color-primary;
    margin: 4px 0;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 60%;
  }
}
.dropdown-menu {
  width: 128px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e6e8eb;
  margin: 0 12px;
}
</style>
