<template>
  <div class="Card" @click="goMovieDetail">
    <div class="Card-image">
      <van-image
        lazy-load
        class="img"
        fit="cover"
        :src="movieImg"
        alt=""
        radius="4"
      ></van-image>
    </div>
    <div class="Card-content">
      <h3 class="Card-title">
        {{ movieTitle }} <span v-if="movieYear"> ({{ movieYear }})</span>
      </h3>
      <div class="Card-score">
        <van-rate
          v-model="movieScore"
          :size="14"
          color="#FED221"
          void-icon="star"
          void-color="#eee"
          allow-half
          readonly
        />
        <span>{{ moviePlayNum }} </span>人点播
      </div>
      <div class="Card-length">
        <span>{{ movieType }}</span>
        <span>{{ movieLength }}分钟</span>
      </div>
      <p class="Card-text" @click="showMore">{{ movieDescription }}</p>
      <!--      <div class="Card-btn"-->
      <!--           @click="playMovie(movieId)">-->
      <!--        <van-icon name="like" color="#ee0a24" v-if="onDemand"/>-->
      <!--        <van-icon name="plus" color="#ee0a24" v-else/>-->
      <!--        <span class="Card-btn-text"  v-if="onDemand">已点播</span>-->
      <!--        <span class="Card-btn-text"  v-else>未点播</span>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'

export default {
  name: 'comMovieCard',
  props: {
    movieId: {
      type: String,
      required: true,
      default: '',
    },
    //年份
    movieYear: {
    },
    // 电影名称
    movieTitle: {
      type: String,
      default: '电影片名',
    },
    // 海报
    movieImg: {
      type: String,
      default:
        'https://image11.m1905.cn/uploadfile/2021/0322/thumb_1_150_203_20210322025648696252.jpg',
    },
    // 评分
    movieScore: {
      type: Number,
      default: 4.5,
    },
    // 点播量
    moviePlayNum: {
      type: Number,
      default: 0,
    },
    movieType: {
      type: String,
      default: '故事',
    },
    movieLength: {
      type: Number,
      default: 120,
    },
    movieDescription: {
      type: String,
      default: '电影简介',
    },
    onDemand: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // 点击点播
    playMovie(movie) {
      console.log('点击点播')
      alert('点击点播' + movie)
      this.$emit('playMovie', movie)
    },
    // 跳转到电影详情
    goMovieDetail() {
      const data = {
        movieId: this.movieId,
        movieTitle: this.movieTitle,
        movieImg: this.movieImg,
        movieScore: this.movieScore,
        moviePlayNum: this.moviePlayNum,
        movieType: this.movieType,
        movieLength: this.movieLength,
        movieDescription: this.movieDescription,
        onDemand: this.onDemand,
      }
      this.$emit('select', data)
      // console.log("跳转到电影详情");
      // Toast("跳转到电影详情"+ this.movieId);
      // this.$router.push({
      //   path: "/filmDetail",
      //   query: {
      //     movieId: this.movieId
      //   }
      // });
    },
    showMore(){
      Dialog.alert({
        title: '电影简介',
        message: this.movieDescription
      }).then(() => {
        // on close
      });
    }
  },
}
</script>

<style scoped lang="less">
.Card {
  position: relative;
  padding-top: 0px;
  display: flex;
  flex-direction: row;
  //width:345px;
  //min-height:150px;
  &-image {
    //width:100px;
    //height:140px;
    padding: 0px 8px 0 0;
    //overflow: hidden;
    border-radius: 10px;
    .img {
      width: 100px;
      height: 135px;
      border-radius: 0.15rem !important;
    }
    //
    //>.img>img{
    //  width:100%;
    //  vertical-align: middle;
    //  margin:0 auto;
    //  border-radius: 5px;
    //}
  }
  &-title {
    font-size: 17px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #363a44;
    line-height: 24px;
    margin-top: 5px;
  }
  &-score {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC, sans-serif;
    font-weight: 500;
    color: #b9bcbf;
    line-height: 18px;
    margin-top: -10px;
    > span {
      width: 30px;
      height: 18px;
      font-size: 14px;
      //font-family: PingFangSC-Medium, PingFang SC,sans-serif;
      font-weight: 500;
      color: @color-primary;
      line-height: 18px;
    }
  }
  &-content {
    width: 70%;
  }
  &-length {
    margin-top: 20px;
    height: 19px;
    width: 120px;
    border-radius: 2px;
    border: 1px solid @color-primary;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: @color-primary;
    line-height: 19px;
    > span {
      display: inline-block;
      text-align: center;
      &:first-child {
        height: 19px;
        line-height: 19px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: white;
        padding: 0 5px;
        background: @color-primary;
        flex: 1;
      }
      &:last-child {
        //width:60px;
        //border:1px solid #000;
        flex: 1.5;
        padding: 0 5px;
      }
    }
  }
  &-text {
    padding: 0;
    margin-top: 8px;
    //width: 230Px;
    height: 36px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC, serif;
    font-weight: 400;
    color: #686b73;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &-btn {
    width: 32px;
    height: 32px;
    background: rgba(164, 105, 105, 0.001);
    box-shadow: 0 4px 10px 0 rgba(136, 168, 200, 0.2);
    border: 2px solid #fff;
    backdrop-filter: blur(3px);
    position: absolute;
    right: 10px;
    top: 10px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 50px;
    &-text {
      position: absolute;
      width: 50px;
      text-align: center;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #363a44;
      line-height: 17px;
      transform: translateY(180%);
    }
  }
}
</style>
